<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-card-title>
        รายละเอียด
        <v-spacer />
        <v-btn text icon @click="$emit('setDialog', false)"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions>
        <!-- <v-data-table
      style="width:100%"
      :headers="headers"
      hide-default-footer>
      </v-data-table> -->
        <table style="width: 100%">
          <tr class="primary white--text">
            <th>ข้อมูล</th>
            <th>ก่อน</th>
            <th>หลัง</th>
          </tr>
          <tr>
            <td>เบอร์มือถือ</td>
            <td>{{ detail.old_member.phone }}</td>
            <td>{{ detail.new_member.phone }}</td>
          </tr>
          <tr>
            <td>ชื่อ-นามสกุล</td>
            <td>{{ detail.old_member.name }}</td>
            <td>{{ detail.new_member.name }}</td>
          </tr>
          <tr>
            <td>Line</td>
            <td>{{ detail.old_member.line }}</td>
            <td>{{ detail.new_member.line }}</td>
          </tr>
          <tr>
            <td>ธนาคาร</td>
            <td>{{ detail.old_member.bank }}</td>
            <td>{{ detail.new_member.bank }}</td>
          </tr>
          <tr>
            <td>เลขบัญชี</td>
            <td>{{ detail.old_member.bank_account }}</td>
            <td>{{ detail.new_member.bank_account }}</td>
          </tr>
          <tr>
            <td>ชื่อบัญชี</td>
            <td>{{ detail.old_member.bank_name }}</td>
            <td>{{ detail.new_member.bank_name }}</td>
          </tr>
          <!-- <tr>
            <td>Turn date</td>
            <td class="warning">{{ detail.old_member }}</td>
            <td class="warning">{{ detail.new_member }}</td>
          </tr> -->
          <!-- <tr>
            <td>Turn over before</td>
            <td>{{ detail.old_member }}</td>
            <td>{{ detail.new_member }}</td>
          </tr>
          <tr>
            <td>Turn over</td>
            <td>{{ detail.old_member }}</td>
            <td>{{ detail.new_member }}</td>
          </tr> -->
          <tr>
            <td>สถานะ</td>
            <td>{{ detail.old_member.type }}</td>
            <td>{{ detail.new_member.type }}</td>
          </tr>
          <!-- <tr>
            <td>Turn over</td>
            <td>{{ detail.old_member }}</td>
            <td>{{ detail.new_member }}</td>
          </tr>
          <tr>
            <td>พันธมิตร</td>
            <td>{{ detail.old_member }}</td>
            <td>{{ detail.new_member }}</td>
          </tr> -->
          <tr>
            <td>Commission Percent</td>
            <td>{{ detail.old_member.percent }}</td>
            <td>{{ detail.new_member.percent }}</td>
          </tr>
          <tr>
            <td>รับโบนัสคืนยอดเสีย</td>
            <td>{{ detail.old_member.cashback }}</td>
            <td>{{ detail.new_member.cashback }}</td>
          </tr>
          <tr>
            <td>turn to do</td>
            <td>{{ detail.old_member.turn_to_do }}</td>
            <td>{{ detail.new_member.turn_to_do }}</td>
          </tr>
          <!-- <tr>
            <td>อยู่ภายใต้ยูส</td>
            <td class="warning">{{ detail.admin.username }}</td>
            <td class="warning">{{ detail.admin.username }}</td>
          </tr> -->
        </table>
      </v-card-actions>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="$emit('setDialog', false)">ตกลง</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ข้อมูล',
        },
        {
          text: 'ก่อน',
        },
        {
          text: 'หลัง',
        },
      ],
    }
  },
}
</script>